import React from "react";
import ProcessFile from '../components/ProcessFile'

function EncryptProfile() {
  
  return (
    <>
    <ProcessFile
      apiRoute="encrypt-profile"
      processingTitle='Encrypting Profile'
      processingDescription='Please wait while the profile is being encrypted and downloaded.'
    />
    </>
  );
}

export default EncryptProfile;
