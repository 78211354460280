import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import KytopenLogoSpinner from './kytopenlogospinner'
import Button from 'react-bootstrap/Button';

function ProcessingModal(props) {
  return (
      <Modal
        show={props.show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>{props.title}</Modal.Header>
        <Modal.Body>
          {props.processing && 
            <div><KytopenLogoSpinner isAnimated={true}/> {props.description}</div>
          }
          {!props.processing && props.errorMsg &&
            <div>Error: {props.errorMsg}</div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.onCloseModal} disabled={props.processing}>Ok</Button>
        </Modal.Footer>
      </Modal>
  );
}

ProcessingModal.propTypes = {
    show: PropTypes.bool,
    processing: PropTypes.bool,
    errorMsg: PropTypes.string,
    onCloseModal: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string

};

export default ProcessingModal;