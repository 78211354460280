import React from "react";
import ProcessFile from '../components/ProcessFile'

function DecryptFile() {
  
  return (
    <ProcessFile
      apiRoute="decrypt-file"
      processingTitle='Decrypting File'
      processingDescription='Please wait while the file is being decrypted and downloaded.'
    />
  );
}

export default DecryptFile;
