export function getFileNameFromResponse(response){
    var filename = "";
    var disposition = response['headers']['content-disposition'];
    if (disposition) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) { 
          filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename;
}


export function getErrorResponseMsg(responseError) {
    if (responseError.response) {
        return String.fromCharCode.apply(null, new Uint8Array(responseError.response.data))
      } else {
        return 'Unexpected error occurred, please check logs';
      }
}
