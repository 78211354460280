import {ToolUI} from '../utils/Constants'
export default function SideNavMenu(props) {
    return (
        <>
        <h2>Tools</h2>
                
        <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
            <li class="nav-item">
                <a href="#" data-bs-toggle="collapse" class="nav-link px-0 align-middle"  onClick={() => props.changeSelectedTool(null)}>Home</a>
            </li>
            <li class="nav-item">                                                        
                <a href="#" data-bs-toggle="collapse" class="nav-link px-0 align-middle" onClick={() => props.changeSelectedTool(ToolUI.decryptFile)}>Decrypt File</a>
            </li>
            <li class="nav-item">
                <a href="#" data-bs-toggle="collapse" class="nav-link px-0 align-middle" onClick={() => props.changeSelectedTool(ToolUI.createTXProfile)}>Create TX Profile</a>
            </li>            
            <li class="nav-item">
                <a href="#" data-bs-toggle="collapse" class="nav-link px-0 align-middle" onClick={() => props.changeSelectedTool(ToolUI.analyzeTXLog)}>Analyze TX Log</a>
            </li>
        </ul>
        </>
    )
}

/*
            <li class="nav-item">
                <a href="#" data-bs-toggle="collapse" class="nav-link px-0 align-middle" onClick={() => props.changeSelectedTool(ToolUI.encryptProfile)}>Encrypt Profile</a>
            </li>
*/