import React from "react";
import ProcessFile from '../components/ProcessFile'

function AnalyzeTXLog() {
  
  return (
    <ProcessFile
      apiRoute="analyze-tx-log"
      processingTitle='Analyzing TX Log'
      processingDescription='Please wait while the TX log file is being analyzed.'
    />
  );
}

export default AnalyzeTXLog;
