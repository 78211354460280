/**
 * Copyright © 2021 Kytopen All Rights Reserved
 * MODULE: components/kytopenlogspinner
 * DESCRIPTION: KytopenLogoSpinner react component.
 */
import React from 'react';

export default function KytopenLogoSpinner(props) {
  const animationAttribute = props.isAnimated ? "true" : undefined;
  const grayscaleClass = props.isGrayscale ? "grayscale" : null;

  // Kytopen colors for the spinner
  const colors = [
    "#ff87ff",
    "#cda0fe",
    "#9e9efe",
    "#729eff",
    "#5cb3fe",
    "#739efe",
    "#9d9dff",
    "#cda0fe",

    "#fe86fe",
    "#fe86c6",
    "#fe868b",
    "#fe9459",
    "#fe9801",
    "#fe9359",
    "#ff878b",
    "#fe86c6",
  ]

  // Render each of the circles in the logo
  let circles = colors.map((color, i) => {
    // Note each circle has a 9px radius, so we multiply by 100-9
    let x_pos = (1 + Math.cos((i-4)/8 * Math.PI))*10;
    let y_pos = (1 + Math.sin((i-4)/8 * Math.PI))*10;
    let style = {
      backgroundColor: color,
      left: x_pos,
      top: y_pos,
      animationDelay: `${(i/8).toFixed(2)}s`
    }
    return (
      <div style={style} key={i} animated={animationAttribute}></div>
    )
  })

  return (
    <div className={"kytopen-logo-spinner " + grayscaleClass}>
      {circles}<span className="spinner-msg"></span>
    </div>
  )
}
