import React, {useState, useEffect} from 'react';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import SideNavMenu from './components/SideNavMenu'
import kytopenLogo from "./components/logo_nav.png"
import ToolTitle from "./components/ToolTitle"
import MainContent from "./components/MainContent"
import ToolDescription from './components/ToolDescription'
import Home from './pages/Home'
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
import './App.scss';

export default function App() {
    useMsalAuthentication(InteractionType.Redirect, null);
    const [selectedTool, setSelectedTool] = useState(null);
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    useEffect(() => {
        if (graphData == null && accounts[0]) {
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    callMsGraph(response.accessToken).then((response) => setGraphData(response));
                });
        }
    })

    let userText = null;
    if (graphData==null) {
        userText = 'Unauthenicated';
    } else {
        userText = `User: ${graphData.userPrincipalName}`;
    }
    const loggedIn = graphData != null;

    return (
        <section id="page">
            {/* top header row */}
            <logo1 is="custom" />
            <menubar is="custom">
            </menubar>
            <header1 is="custom"></header1>

            {/* middle header row */}
            <logo2 is="custom"><img src={kytopenLogo} alt="Kytopen" width="230px" /></logo2>
            <header2 is="custom">
                {loggedIn && (
                    <ToolTitle selectedTool={selectedTool} />
                )}
            </header2>
            {/* bottom header row */}
            <logo3  is="custom" />
            <header3 is="custom">
                <div class="header-user-box">
                    {userText}
                </div>
            </header3>
            {/* left nav and main screen content */}
            <nav is="custom">
            <div id="workflow-nav">
                {loggedIn && (
                    <SideNavMenu
                        changeSelectedTool={(toolUI)=>setSelectedTool(toolUI)}
                    />
                    )
                }

            </div>
            </nav>
            <main is="custom">
                {loggedIn && selectedTool && (
                    <>
                        <ToolDescription selectedTool={selectedTool} />
                        <MainContent selectedTool={selectedTool} />
                    </>
                )}
                {loggedIn && !selectedTool && (
                    <Home/>
                )}
                {!loggedIn &&
                    <div className="not-logged-in">
                        Please sign-in to use Kytopen internal tools
                    </div>
                }
            </main>

            {/* footer row */}
            <navfooter is="custom">© 2020-2022 Kytopen</navfooter>
            <mainfooter is="custom"></mainfooter>
        </section>)
}
