import React, { useState, useEffect } from "react";
import axios from "axios"
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import ProcessingModal from './ProcessingModal'
import PropTypes from 'prop-types';
import {getErrorResponseMsg, getFileNameFromResponse} from '../utils/AxiosUtils'

const apiDNS = process.env.REACT_APP_API_DNS;

function ProcessFile(props) {
  const { instance, accounts } = useMsal();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    console.log(selectedFile)
  }, [selectedFile])


  function apiCall(selectedFile) {
    setProcessing(true);
    setErrorMsg(null);
    setShowProcessingModal(true);

    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
        .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        })
        .then((response) => {
          uploadFile(selectedFile, `Bearer ${response.accessToken}`);
        });
  }


  const uploadFile = async (selectedFile, bearerToken) => {
    /* console.log("file name: ", selectedFile["name"]) */
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      axios.post(`${apiDNS}api/${props.apiRoute}`, formData, {
        responseType:"arraybuffer",
        headers: {
          "Content-Type":"multipart/form-data",
          "Authorization": bearerToken
        },
      })
      .then((response) => {
        const file = new Blob([response.data]);
        let fileName = getFileNameFromResponse(response);
        if (fileName==='') {
          fileName = 'result'
        }
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.download = fileName;
        link.href = url;
        link.click();
        setShowProcessingModal(false);
        setProcessing(false);
      })
      .catch(function (error) {
        setProcessing(false);
        setErrorMsg(getErrorResponseMsg(error));
      });
    } catch (error) {
      console.log("This is the error ===> ", error);
    }
  }

  const handleSelectedFile = (file) => {
    setFileName(file["name"]);
    setSelectedFile(file);
  }

  return (
      <>
      <div className='fileUpload'>
        <input type = "file" id="fileName" onChange={(event) => handleSelectedFile(event.target.files[0])}>
        </input>
        <button onClick={() => apiCall(selectedFile)}> Download File </button>
      </div>
      <ProcessingModal
        show={showProcessingModal}
        processing={processing}
        errorMsg={errorMsg}
        onCloseModal={()=>setShowProcessingModal(false)}
        title={props.processingTitle}
        description={props.processingDescription}
      />
      </>
  );
}


ProcessingModal.propTypes = {
  apiRoute: PropTypes.string,
  processingTitle: PropTypes.string,
  processingDescription: PropTypes.string
};

export default ProcessFile;
