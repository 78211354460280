import React, { useState, useEffect } from "react";
import axios from "axios"
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import ProcessingModal from '../components/ProcessingModal'
import {getErrorResponseMsg, getFileNameFromResponse} from '../utils/AxiosUtils'
import InputNumber from 'rc-input-number';
import "rc-input-number/assets/index.css"
import './CreateTXProfile.scss';
/*
name = LV Test
description = Profile with standard electricity
pump-speed = 50
wave-shape = square_wave
wave-freq = 251
wave-amplitude = 600
wave-offset = 0
wave-duty_cycle = .1
*/

const apiDNS = process.env.REACT_APP_API_DNS;

function CreateTXProfile() {
  const { instance, accounts } = useMsal();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [pumpSpeed, setPumpSpeed] = useState('');
  const [waveShape, setWaveShape] = useState('square_wave');
  const [waveFrequency, setWaveFrequency] = useState('');
  const [waveAmplitude, setWaveAmplitude] = useState('');
  const [waveOffset, setWaveOffset] = useState(0);
  const [waveDutyCycle, setWaveDutyCycle] = useState(.5);

  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [createProfileBtnDisabled, setCreateProfileBtnDisabled] = useState(false);

  const createProfileFormData = () => {
    return {
      name: name,
      description: description,
      pumpSpeed: pumpSpeed,
      waveShape: waveShape,
      waveFrequency: waveFrequency,
      waveAmplitude: waveAmplitude,
      waveOffset: waveOffset,
      waveDutyCycle: waveDutyCycle
    }
  }

  useEffect(() => {
    const formData = createProfileFormData();
    var values = Object.keys(createProfileFormData()).map(function(key){
      return formData[key];
    });
    setCreateProfileBtnDisabled(values.includes('')||values.includes(null));
  }, [name, description, pumpSpeed, waveShape, waveFrequency, waveAmplitude, waveOffset, waveDutyCycle]);

  const amplitudeNormalization = (num) => {
    if (num===0) {
      return `0${num}`
    }
    return num
  };

  const createProfile = () => {
      setProcessing(true);
      setErrorMsg(null);
      setShowProcessingModal(true);
      // Silently acquires an access token which is then attached to a request for MS Graph data
      instance
          .acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
          })
          .then((response) => {

            try {
              axios.post(`${apiDNS}api/create-tx-profile`, createProfileFormData(), {
                responseType:"arraybuffer",
                headers: {
                  "Content-Type":"application/json",
                  "Authorization": `Bearer ${response.accessToken}`
                },
              })
              .then((response) => {
                const file = new Blob([response.data]);
                let fileName = getFileNameFromResponse(response);
                if (fileName==='') {
                  fileName = 'result'
                }
                const url = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.download = fileName;
                link.href = url;
                link.click();
                setShowProcessingModal(false);
                setProcessing(false);
              })
              .catch(function (error) {
                setProcessing(false);
                setErrorMsg(getErrorResponseMsg(error));
              });
            } catch (error) {
              console.log("This is the error ===> ", error);
            }
          });
  }

  return (
    <>
    <div className="row">
      <div className="column">
        <div>
          <label htmlFor="profile-name">Name <span className="required"> *</span></label>
          <input type="text" value={name} id="profile-name" onChange={(event) => setName(event.target.value) } />
          <span className="input-note"></span>
        </div>
        <div>
          <label htmlFor="profile-description">Description <span className="required"> *</span></label>
          <input type="text" value={description} id="profile-description" onChange={(event) => setDescription(event.target.value)}/>
          <span className="input-note"></span>
        </div>
        <div>
          <label htmlFor="profile-pump-speed">Pump Speed <span className="required"> *</span></label>
          <InputNumber id="profile-pump-speed" precision={0} min={1} max={50} step={10} required={true} value={pumpSpeed} controls={false} onChange={(value) => setPumpSpeed(value)}  />
          <span className="input-note">mL/min (1,50) - The flow rate at which an individual sample travels through the flow chamber</span>
        </div>
        <div>
          <label htmlFor="profile-wave-shape">Wave Shape <span className="required"> *</span></label>

          <select value={waveShape} id="profile-wave-shape" onChange={(event) => setWaveShape(event.target.value)}>
              <option value="sine_wave">Sine Wave</option>
              <option value="triangle_wave">Triangle Wave</option>
              <option value="square_wave">Square Wave</option>
          </select>
          <span className="input-note"></span>
        </div>
      </div>
      <div className="column">          
        <div>
          <label htmlFor="profile-wave-frequency">Wave Frequency <span className="required"> *</span></label>
          <InputNumber id="profile-wave-frequency" precision={0} min={100} max={1000} step={100} required={true} value={waveFrequency} controls={false} onChange={(value) => setWaveFrequency(value)} />
          <span className="input-note">Hz (100,1000) - The amount of time a sample receives a charge from the electrode</span>
        </div>
        <div>
          <label htmlFor="profile-wave-amplitude">Wave Amplitude <span className="required"> *</span></label>
          <InputNumber id="profile-wave-amplitude" min={0} max={700} step={100} required={true} value={waveAmplitude} controls={false} onChange={(value) => setWaveAmplitude(value)} formatter={amplitudeNormalization}  />
          <span className="input-note">Volts (0, 700) - The voltage difference from the peak to trough of the wave</span>
        </div>
        <div>
          <label htmlFor="profile-wave-offset">Wave Offset <span className="required"> *</span></label>
          <InputNumber id="profile-wave-offset" min={-500} max={500} step={100} defaultValue={0} required={true} value={waveOffset} controls={false} onChange={(value) => setWaveOffset(value)}  />
          <span className="input-note">Volts (-500, 500) - The base mean voltage that the wave amplitude is based from</span>
        </div>
        <div>
          <label htmlFor="profile-wave-duty_cycle">Wave Duty Cycle <span className="required"> *</span></label>
          <InputNumber id="profile-wave-duty_cycle" min={0} max={1} step={.1} defaultValue={.5} required={true} value={waveDutyCycle} controls={false} onChange={(value) => setWaveDutyCycle(value)}  />
          <span className="input-note">ms (0.0, 1.0) - The amount of time the highest electrified signal is sent to a sample</span>
        </div>
        <div>
          <button onClick={createProfile} disabled={createProfileBtnDisabled}> Create Profile </button>
        </div>
      </div>
    </div>


      
      <ProcessingModal
        show={showProcessingModal}
        processing={processing}
        errorMsg={errorMsg}
        onCloseModal={()=>setShowProcessingModal(false)}
        title='Create TX Profile'
        description='Creating TX profile and encrypting it'
      />
    </>
  );
}

export default CreateTXProfile;
