import {ToolUI} from "../utils/Constants"
export default function ToolDescription (props) {
    let description = ''
    switch (props.selectedTool) {
        case ToolUI.decryptFile:
            description = 'Decrypt any Discover/TX log or profile.'
            break;
        case ToolUI.encryptProfile:
            description = 'Encrypt a valid profile so it can be imported onto the TX. IMPOTANT: DO NOT use this for creating discover profiles.'
            break;
        case ToolUI.analyzeTXLog:
            description = 'Analyze a decrypted TX detail log.'
            break;
        case ToolUI.createTXProfile:
            description = 'Create an encrypted profile that can be uploaded to a TX'
            break;
    }
    return (
        <h5 class='tool-description'>{description}</h5>
    )
}
