import DecryptFile from '../pages/DecryptFile'
import EncryptProfile from '../pages/EncryptProfile'
import AnalyzeTXLog from '../pages/AnalyzeTXLog'
import {ToolUI} from "../utils/Constants"
import CreateTXProfile from "../pages/CreateTXProfile"

export default function MainContent(props) {
    switch (props.selectedTool) {
        case ToolUI.decryptFile:
            return <DecryptFile/>
        case ToolUI.encryptProfile:
            return <EncryptProfile/>
        case ToolUI.analyzeTXLog:
            return <AnalyzeTXLog/>
        case ToolUI.createTXProfile:
            return <CreateTXProfile/>
    }
}
