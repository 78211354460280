import React from "react";

function Home() {
  
  return (
    <div class="wrapper home-description">
      <div class="box">Internal Tools are for Kytopen employees only. These tools deal with the files that contain proprietary data and should be maintained as such.</div>
  </div>
  );
}

export default Home;
